/* Add your own styles as needed */
.accordion {
    width: 100%;
  }
  
  .accordion-item {
    margin-bottom: 10px;
  }
  
  .titleasd {
    background-color: #f0f0f0;
    padding: 10px;
    cursor: pointer;
    font-weight: 500; /* Add bold font weight */
    color: #000; /* Set text color to black */
    border: none; /* Remove borders */
  }
  
  .titleasd.active {
    background-color: #ddd;
  }
  
  .content {
    display: none;
    padding: 10px;
  }
  
  .content.active {
    display: block;
  }
  